import React, {Component} from 'react';

class Footer extends Component {

    render() {
        return (
            <section className='section section-footer'>
              <div className='container'>
                <div className='row footer-content'>
                  <div className='col-8 col-md-6 col-lg-7 footer-img'>
                    <img className='skill-logo' alt='' src='./../img/gallinago_logo_green.svg'></img>
                  </div>
                  <div className='col-12 col-md-4 col-lg-3 contact-info'>
                    <div>
                      <p className="contact-name">Bartosz Olszowski</p>
                      <p>bartosz.olszowski@gallinago.pl</p>
                      <p>(+48) 510 175 456</p>
                    </div>
                  </div>
                  <div className='social-media-info'>
                    <a href="https://www.facebook.com/Gallinago.agency" target="_blank" rel="noopener noreferrer">Facebook</a>
                    <a href="https://www.linkedin.com/company/gallinago/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href="https://github.com/Gallinago" target="_blank" rel="noopener noreferrer">Github</a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 footer-info'>
                    {/* <a href="#">Privacy policy</a> */}
                    <p>© 2023 - Gallinago - All rights reserved</p>
                  </div>
                </div>
              </div>
            </section>
        )
    }
}

export default Footer;

