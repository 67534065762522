import React, {Component} from 'react';
import Select from 'react-select';

class DetailedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        phone: "",
        email: "",
        message: "",
        www: "",
        products: "",
        erp: "",
        erp_name: "",
        crm: "",
        crm_name: "",
        pim: "",
        pim_name: "",
        budget:"",
      },
      isSubmitting: false,
      isError: false,
    };

    this.validateForm = this.validateForm.bind(this);
    this.showNameOpt = this.showNameOpt.bind(this);
  }

  handleInputChange = e =>
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value }
    });

  validateForm = (e) => {
    this.props.validateForm(e)
  }

  showNameOpt =  (e) => {
    let optInput = e.target.closest('.field-group').querySelector('.field-label-opt')
    
    optInput.classList.add('visible')
  }

  hideNameOpt =  (e) => {
    let optInput = e.target.closest('.field-group').querySelector('.field-label-opt')

    optInput.classList.remove('visible')
  }

  render() {
    const options = [
      { value: '<10k$', label: 'up to 10k $' },
      { value: '10k$25k$', label: '10k - 25k $' },
      { value: '25k$50k$', label: '25k - 50k $' },
      { value: '50k$100k$', label: '50k - 100k $' },
      { value: '>100k$', label: 'above 100k $' },
    ];
  
    const selectStyles = {
      option: (provided, state) => ({
        ...provided,
        border: '0',
        borderRadius: '0',
        fontSize: '16px',
        lineHeight: '28px',
        backgroundColor: state.isFocused ? '#0070ad' : "white",    backgroundImage: state.isFocused ? 'linear-gradient(101deg, #3ead58 -38%, #c8d84b 98%)' : "white",
        color: state.isFocused ? 'white' : "#686868",
        fontWeight: '500',
        padding: '10px 20px',
      }),
      control: (provided, state) => ({
        width: '100%',
        display: 'flex',
        border: '1px solid #cccaca',
        padding: '4px 15px',
        '&:hover': {
          border: 'solid 1px #6bbb54'
        },
        '&:focus': {
          border: 'solid 1px #6bbb54'
        },
        '&:active': {
          border: 'solid 1px #6bbb54'
        }
      }),
      container: (provided, state) => ({
        marginTop: '12px',
      }),
      menu: (provided, state) => ({
        border: '1px solid #cccaca',
        borderRadius: '0',
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        '&:hover': {
          color: '#6bbb54'
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '0'
      }),
      placeholder: (base) => ({
        ...base,
        position: 'static',
        fontSize: '16px',
        transform: 'none'
      })
    }

    return (
      <form id='detailed-form' className='form detailed-form' noValidate onSubmit={this.validateForm}>
        <div className="row">
          <div className='col-12 col-md-6'>
            <label className='field-label' htmlFor="name_d">
              <p className='field-label-name'>Name</p>
              <input 
                className='field-input validate-element'
                type="text"
                id="name_d"
                name="name"
                placeholder="Type your name here"
                value={this.state.values.name}
                onChange={this.handleInputChange}
                data-errorempty='Please enter name'
                data-errorinvalid='Name is invalid'
                required
              />
              <span className="error hidden" aria-live="polite"></span>
            </label>

            <label className='field-label' htmlFor="phone_d">
              <p className='field-label-name'>Phone</p>
              <input
                className='field-input validate-element'
                type="tel"
                id="phone_d"
                name="phone"
                placeholder="Type your phone number"
                value={this.state.values.phone}
                onChange={this.handleInputChange}
                data-errorempty='Please enter phone number'
                data-errorinvalid='Phone number is invalid'
                pattern="^(?:[+\d].*\d|\d)$"
                required
              />
              <span className="error hidden" aria-live="polite"></span>
            </label>

            <label className='field-label' htmlFor="email_d">
              <p className='field-label-name'>Email</p>
              <input
                className='field-input validate-element'
                type="email"
                id="email_d"
                name="email"
                placeholder="Type your email"
                value={this.state.values.email}
                onChange={this.handleInputChange}
                data-errorempty='Please enter email'
                data-errorinvalid='Email is invalid'
                required
              />
              <span className="error hidden" aria-live="polite"></span>
            </label>

            <label className='field-label' htmlFor="www_d">
              <p className='field-label-name'>WWW (optional)</p>
              <input
                className='field-input'
                type="text"
                id="www_d"
                name="www"
                placeholder="Type address of your site"
              />
            </label>

            <label className='field-label' htmlFor="products_no_d">
              <p className='field-label-name'>Number of products in your store (optional)</p>
              <input
                className='field-input validate-element'
                type="text"
                id="products_no_d"
                name="products"
                placeholder="Type number"
                value={this.state.values.products}
                onChange={this.handleInputChange}
                data-errorempty=''
                data-errorinvalid='Products number is invalid'
                pattern="\d*"
              />
              <span className="error hidden" aria-live="polite"></span>
            </label>

            <div className='field-group'>
              <label className='field-label field-radio-label' htmlFor="erp">
                  <p className='field-label-name'>ERP Integration
                  <span className="field-label-icon">
                    <img src='./../img/tooltip.svg' alt='tooltip icon'/>
                  </span>
                  <span className="field-label-info">Enterprise Resource Planning</span>
                </p>
                <div className='radios-container validate-radio' id="erp_d" onChange={this.handleInputChange}>
                  <input
                    className='field-radio'
                    type='radio'
                    name='erp'
                    value='yes'
                    onClick={this.showNameOpt}
                  />
                  <label htmlFor='yes'>Yes</label>
                  <input
                    className='field-radio'
                    type='radio'
                    name='erp'
                    value='no'
                    onClick={this.hideNameOpt}
                  />
                  <label htmlFor='no'>No</label>
                </div>
                <span className="error hidden" aria-live="polite"></span>
              </label>

              <label className='field-label field-label-opt' htmlFor="erp_name">
                <p className='field-label-name'>ERP Name (optional)</p>
                <input
                  className='field-input'
                  type="text"
                  id="erp_name_d"
                  name="erp_name"
                  placeholder="Type ERP Name"
                />
                <span className="error hidden" aria-live="polite"></span>
              </label>
            </div>

            <div className='field-group'>
              <label className='field-label field-radio-label' htmlFor="crm">
                <p className='field-label-name'>CRM Integration
                <span className="field-label-icon">
                  <img src='./../img/tooltip.svg' alt='tooltip icon' />
                </span>
                <span className="field-label-info">Customer Relationship Management</span>
                </p>
                <div className='radios-container validate-radio' id='crm_d'>
                  <input
                    className='field-radio'
                    type='radio'
                    name='crm'
                    value='yes'
                    onClick={this.showNameOpt}
                  />
                  <label htmlFor='yes'>Yes</label>
                  <input
                    className='field-radio'
                    type='radio'
                    name='crm'
                    value='no'
                    onClick={this.hideNameOpt}
                  />
                  <label htmlFor='no'>No</label>
                </div>
              <span className="error hidden" aria-live="polite"></span>
            </label>

              <label className='field-label field-label-opt' htmlFor="crm_name">
                <p className='field-label-name'>CRM Name (optional)</p>
                <input
                  className='field-input'
                  type="text"
                  id="crm_name_d"
                  name="crm_name"
                  placeholder="Type CRM Name"
                />
                <span className="error hidden" aria-live="polite"></span>
              </label>
            </div>

            <div className='field-group'>
              <label className='field-label field-radio-label' htmlFor="pim">
                  <p className='field-label-name'>PIM Integration
                  <span className="field-label-icon">
                    <img src='./../img/tooltip.svg' alt='tooltip icon' />
                  </span>
                  <span className="field-label-info">Product Information Management</span>
                </p>
                <div className='radios-container validate-radio' id='pim_d'>
                  <input
                    className='field-radio'
                    type='radio'
                    name='pim'
                    value='yes'
                    onClick={this.showNameOpt}
                  />
                  <label htmlFor='yes'>Yes</label>
                  <input
                    className='field-radio'
                    type='radio'
                    name='pim'
                    value='no'
                    onClick={this.hideNameOpt}
                  />
                  <label htmlFor='no'>No</label>
                </div>
                <span className="error hidden" aria-live="polite"></span>
              </label>
              <label className='field-label field-label-opt' htmlFor="pim_name">
                <p className='field-label-name'>PIM Name (optional)</p>
                <input
                  className='field-input'
                  type="text"
                  id="pim_name_d"
                  name="pim_name"
                  placeholder="Type PIM Name"
                />
                <span className="error hidden" aria-live="polite"></span>
              </label>
            </div>

            <label className='field-label field-select' htmlFor="budget">Budget (optional)
              <Select
                name="budget"
                styles = {selectStyles}
                options={options}
                placeholder={'Select your budget'}
              />
            </label>
          </div>
          <div className='col-12 col-md-6'>
            <label className='field-label field-textarea-label field-textarea-label-d' htmlFor="description">
              <p className='field-label-name'>Tell us more about your project</p>
              <textarea
                className='field-textarea field-textarea-d validate-element'
                id="description_d"
                name="message"
                placeholder="Tell us more about your project" 
                value={this.state.values.message}
                onChange={this.handleInputChange}
                data-errorempty='Please enter message'
                data-errorinvalid='Message is invalid'
                required
              />
              <span className="error hidden" aria-live="polite"></span>
            </label>
          </div>
        </div>
        <div className="row row-submit">
          <div className='col-12'>
              <input type="submit" className='field-submit btn gallinago-btn--secondary' id="submit_d" name="user_submit_d"  value="Send"/>
          </div>
        </div>
      </form>
    )
  }
}

export default DetailedForm;

