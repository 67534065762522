import React, {Component} from 'react';

class SimpleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        phone: "",
        email: "",
        message: "",
        www: "",
      },
      isSubmitting: false,
      isError: false
    };

    this.validateForm = this.validateForm.bind(this);
  }

  handleInputChange = e =>
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value }
    });

    validateForm = (e) => {
      this.props.validateForm(e)
    }

    render() {

        return (
          <form id='simple-form' className='form simple-form' noValidate onSubmit={this.validateForm}>
              <div className="row">
                <div className='col-12 col-md-6'>
                  <label className='field-label' htmlFor="name">
                    <p className='field-label-name'>Name</p>
                    <input 
                      className='field-input validate-element'
                      type="text"
                      id="name_s"
                      name="name"
                      placeholder="Type your name here"
                      required
                      value={this.state.values.name}
                      onChange={this.handleInputChange}
                      data-errorempty='Please enter name'
                      data-errorinvalid='Name is invalid'
                      title="name"
                    />
                    <span className="error hidden" aria-live="polite"></span>
                  </label>

                  <label className='field-label' htmlFor="phone">
                    <p className='field-label-name'>Phone</p>
                    <input 
                      className='field-input validate-element'
                      type="tel"
                      id="phone_s"
                      name="phone"
                      placeholder="Type your phone number"
                      pattern="^(?:[+\d].*\d|\d)$"
                      value={this.state.values.phone}
                      onChange={this.handleInputChange}
                      data-errorempty='Please enter phone number'
                      data-errorinvalid='Phone number is invalid'
                      required
                    />
                    <span className="error hidden" aria-live="polite"></span>
                  </label>

                  <label className='field-label' htmlFor="email">
                    <p className='field-label-name'>Email</p>
                    <input 
                      className='field-input validate-element'
                      type="email"
                      id="email_s"
                      name="email"
                      placeholder="Type your email"
                      value={this.state.values.email}
                      onChange={this.handleInputChange}
                      data-errorempty='Please enter email address'
                      data-errorinvalid='Email address is invalid'
                      required
                    />
                    <span className="error hidden" aria-live="polite"></span>
                  </label>

                  <label className='field-label' htmlFor="www">
                    <p className='field-label-name'>WWW (optional)</p>
                    <input
                      className='field-input'
                      type="text"
                      id="www_s"
                      name="www"
                      placeholder="Type address of your site"
                      value={this.state.values.www}
                      onChange={this.handleInputChange}
                    />
                  </label>
                </div>

                <div className='col-12 col-md-6'>
                  <label className='field-label field-textarea-label' htmlFor="message">
                    <p className='field-label-name'>Tell us more about your project</p>
                    <textarea
                      className='field-textarea validate-element'
                      id="description_s"
                      name="message"
                      placeholder="Tell us more about your project"
                      value={this.state.values.message}
                      onChange={this.handleInputChange}
                      data-errorempty='Please enter description'
                      data-errorinvalid=''
                      required
                    />
                    <span className="error hidden" aria-live="polite"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className='col-12'>
                    <input type="submit" className='field-submit btn gallinago-btn--secondary' id="submit_s" name="user_submit"  value="Send"/>
                </div>
              </div>
            </form>
        )
    }
}

export default SimpleForm;

