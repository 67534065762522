import React, {Component} from 'react';

class Skills extends Component {

    render() {
        return (
            <section className='section section-skills'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <h2 className='heading-secondary heading-secondary--dark'>Our software expertise</h2>
                  </div>
                </div>
                <div className='row-skill-container'>
                  <div className='skill-container'>
                    <img className='skill-logo' alt='' src='./../img/magento_logo.svg'></img>
                    <p className='skill-desc'>Magento</p>
                  </div>
                  <div className='skill-container'>
                    <img className='skill-logo' alt='' src='./../img/prestashop_logo.svg'></img>
                    <p className='skill-desc'>Prestahop</p>
                  </div>
                  <div className='skill-container'>
                    <img className='skill-logo' alt='' src='./../img/shopware_logo.svg'></img>
                    <p className='skill-desc'>Shopware</p>
                  </div>
                  <div className='skill-container'>
                    <img className='skill-logo' alt='' src='./../img/woo_commerce_logo.svg'></img>
                    <p className='skill-desc'>WooCommerce</p>
                  </div>
                  <div className='skill-container'>
                    <img className='skill-logo' alt='' src='./../img/shopify_logo.png'></img>
                    <p className='skill-desc'>Shopify</p>
                  </div>
                  <div className='skill-container'>
                    <img className='skill-logo' alt='' src='./../img/custom-development.svg'></img>
                    <p className='skill-desc'>Custom Development</p>
                  </div>
                </div>
              </div>
            </section>
        )
    }
}

export default Skills;

