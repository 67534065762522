import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SimpleForm from './SimpleForm';
import DetailedForm from './DetailedForm';
import SuccessMsg from './SuccessMsg';
import 'bootstrap/dist/css/bootstrap.min.css';

function Popup() {
  const [show, setShow] = useState(false);
  const [key, setKey] = useState('detailed-form-tab');
  const [success, setSuccess] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const scrollToTab = function(e, elem) {
    let tab = elem.target
    setKey(e)
    tab.scrollIntoView({inline: "center", block: "nearest"})
  }

  const validateInput = function (elem) {
    let elementID =  elem.id
    let error = document.querySelector(`#${elementID} + span.error`)

    if (elem.validity.valid) {
      error.innerHTML = '';
      error.className = 'error';
      elem.classList.remove('invalid')

      return true
    } else {
      showError();

      return false
    }

    function showError() {
      if(elem.validity.valueMissing) {
        error.textContent = elem.dataset.errorempty;
        elem.classList.add('invalid')
      } else if(elem.validity.typeMismatch || elem.validity.patternMismatch) {
        error.textContent = elem.dataset.errorinvalid;
      }
    
      // Set the styling appropriately
      error.className = 'error active';
    }
  }

  const validateRadio = function (element) {
    var radios = Array.prototype.slice.call(element.querySelectorAll('.field-radio'))
    var error = element.nextSibling

    if (radios[0].checked === true || radios[1].checked) {
      error.innerHTML = '';
      error.className = 'error';
      element.classList.remove('invalid')

      return true
    } else {
      showError();

      return false
    }

    function showError() {
      error.textContent = 'Please select one';
      element.classList.add('invalid')
      error.className = 'error active'
    }
  }

  const validateForm = function (e) {
    e.preventDefault()
    let validateElems = Array.from(e.target.getElementsByClassName('validate-element'));
    let validateRadios = Array.from(e.target.getElementsByClassName('validate-radio'));

    let formValid = true;
    validateElems.forEach(element => {
      formValid = validateInput(element) && formValid
    });
    if (validateRadios) {
      validateRadios.forEach(element => {
        formValid = validateRadio(element) && formValid
      });
    }

    if (formValid) {
      //send form data to endpoint
      let formData = new FormData();
      formData.append('name', e.target.querySelector("[name='name']").value);
      formData.append('email', e.target.querySelector("[name='email']").value);
      formData.append('phone', e.target.querySelector("[name='phone']").value);
      formData.append('message', e.target.querySelector("[name='message']").value);
      if (e.target.querySelector("[name='www']")) {
        formData.append('www', e.target.querySelector("[name='www']").value);
      }
      if (e.target.querySelector("[name='products']")) {
        formData.append('products', e.target.querySelector("[name='products']").value);
      }
      if (e.target.querySelector("[name='erp']")) {
        formData.append('erp', e.target.querySelector("[name='erp']:checked").value);
      }
      if (e.target.querySelector("[name='erp_name']")) {
        formData.append('erp_name', e.target.querySelector("[name='erp_name']").value);
      }
      if (e.target.querySelector("[name='crm']")) {
        formData.append('crm', e.target.querySelector("[name='crm']:checked").value);
      }
      if (e.target.querySelector("[name='crm_name']")) {
        formData.append('crm_name', e.target.querySelector("[name='crm_name']").value);
      }
      if (e.target.querySelector("[name='pim']")) {
        formData.append('pim', e.target.querySelector("[name='pim']:checked").value);
      }
      if (e.target.querySelector("[name='pim_name']")) {
        formData.append('pim_name', e.target.querySelector("[name='pim_name']").value);
      }
      if (e.target.querySelector("[name='budget']")) {
        formData.append('budget', e.target.querySelector("[name='budget']").value);
      }

      const res = fetch("https://gallinago.pl:8080/contact", {
        method: "POST",
        body: formData,
      })
      .then(function(res) {
        if (res.ok) {
            setSuccess(true)
        }
    });
    }
  }

  useEffect(() => {
    const simpleForm  = document.getElementById('simple-form');
    const detailedForm  = document.getElementById('detailed-form');

    if (simpleForm) {
      let validateElemsS = Array.from(simpleForm.getElementsByClassName('validate-element'));

      validateElemsS.forEach(element => {
        element.addEventListener('input', function (event) {
          validateInput(element)
        });
      });
    }
    if (detailedForm) {
      let validateElemsD = Array.from(detailedForm.getElementsByClassName('validate-element'));

      validateElemsD.forEach(element => {
        element.addEventListener('input', function () {
          validateInput(element)
        });
      });

      let validateRadios = Array.from(detailedForm.getElementsByClassName('validate-radio'));

      validateRadios.forEach(element => {
        element.addEventListener('change', function () {
          validateRadio(element)
        });
      });
    }
  });

  return (
      <>
      <Button className='btn gallinago-btn--secondary contact-button' onClick={() => { handleShow();}}>
          Contact
      </Button>

      <Modal id='contact-popup' className='popup popup-services' show={show} onHide={handleClose}>
        <Modal.Header closeButton className='popup-header'>
          <Modal.Title className= 'heading-secondary heading-secondary--small'>
            <img className='navigation-icon' src='./../img/gallinago_logo.svg' alt='gallinago logo'></img>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Tabs activeKey={key} id='form-tabs' onSelect={scrollToTab}>
            <Tab eventKey='simple-form-tab' title='Simple contact' className='form-tab simple-form-tab'>
              <div className='simple-form-container'>
                {success !== true ? (
                  <SimpleForm validateForm={validateForm}/>
                ) : (
                  <SuccessMsg />
                )}
              </div> 
            </Tab>
            <Tab eventKey='detailed-form-tab' title='Detailed contact' className='form-tab detailed-form-tab'>
              <div className='detailed-form-container'>
                {success !== true ? (
                  <DetailedForm validateForm={validateForm}/>
                ) : (
                  <SuccessMsg />
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      </>
  );
}

class Contact extends React.Component {
    render() {
      return (
        <section className='section section-contact' >
            <div className='container'>
              <div className='contact-tile'>
                <p className='contact-paragraph'>Got a project or collaboration in mind?</p>
                <p className='contact-paragraph'>Everything starts with a chat…</p>
                <Popup />
              </div>
          </div>
        </section>
      );
    }
  }
  
  export default Contact;