import React, {Component} from 'react';

class Team extends Component {

    render() {
        return (
            <section className='section section-team'>
              <div className='container'>
                <div className='row team-members'>
                  <div className='team-member'>
                    <img className='team-member-photo' alt='bartosz olszowski' src='./../img/bartosz_olszowski.jpg'></img>
                    <div className='team-name-container'>
                      <p className='team-name'>Bartosz Olszowski</p>
                      <a href="tel:+48510175456"><img className='team-icon' alt='phone icon' src='./../img/phone_icon.svg'></img></a>
                    </div>
                    <p className='team-role'>CEO</p>
                    <a className='team-mail' href="mailto:bartosz.olszowski@gallinago.pl">bartosz.olszowski@gallinago.pl</a>
                  </div>
                </div>
              </div>
            </section>
        )
    }
}

export default Team;

