import React, {Component} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Clients extends Component {

    render() {
        var settings = {
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 374,
              settings: {
                slidesToShow: 1,
              }
            }
          ],
        };
        return (
            <section className='section section-clients'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <h2 className='heading-secondary heading-secondary--dark'>We worked for</h2>
                  </div>
                </div>
                <div className='row-logo-container'>
                  <Slider {...settings}>
                    <div className="single-logo-container">
                      <div className="logo-container">
                        <img className="client-logo" alt="WMASG logo" src="./../img/logo-wmasg.png"></img>
                      </div>
                    </div>
                    <div className="single-logo-container">
                      <div className="logo-container">
                        <img className="client-logo" alt="Loremo logo" src="./../img/Loremo-Logo-inverse.png"></img>
                      </div>
                    </div>
                    <div className="single-logo-container">
                      <div className="logo-container">
                        <img className="client-logo" alt="SKWeld logo" src="./../img/Skweld_logo.png"></img>
                      </div>
                    </div>
                    <div className='single-logo-container'>
                      <div className='logo-container'>
                        <img className='client-logo' alt='Olimp  logo' src='./../img/olimp_logo.jpg'></img>
                      </div>
                    </div>
                    <div className="single-logo-container">
                      <div className="logo-container">
                        <img className="client-logo" alt="SlipStop logo" src="./../img/slipstop-logo.png"></img>
                      </div>
                    </div>
                    <div className='single-logo-container'>
                      <div className='logo-container'>
                        <img className='client-logo' alt='Winkhaus logo' src='./../img/winkhaus_logo.png'></img>
                      </div>
                    </div>
                    <div className='single-logo-container'>
                      <div className='logo-container'>
                        <img className='client-logo' alt='Olive logo' src='./../img/olive_logo.jpg'></img>
                      </div>
                    </div>
                    <div className='single-logo-container'>
                      <div className='logo-container'>
                        <img className='client-logo' alt='State of style logo' src='./../img/sos_logo.svg'></img>
                      </div>
                    </div>
                    <div className='single-logo-container'>
                      <div className='logo-container'>
                        <img className='client-logo' alt='SKW Racing Park logo' src='./../img/skw_logo.jpg'></img>
                      </div>
                    </div>
                    <div className='single-logo-container'>
                      <div className='logo-container'>
                        <img className='client-logo' alt='Otokom logo' src='./../img/otokom_logo.svg'></img>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </section>
        )
    }
}

export default Clients;

