import React, {Component} from 'react';
import {
    Route,
    // NavLink,
    BrowserRouter
  } from "react-router-dom";
import Home from './Home';
import { Link } from 'react-scroll'

class Main extends Component {

    render() {
        return (
            <BrowserRouter>
                <nav className='navigation' id='section-menu'>
                  <div className="container">
                    <div className="navigation-main">
                      <div className='navigation-left navigation-icon-continer'>
                          {/* <NavLink className='navigation-menu-link' exact to='/'>
                              <img className='navigation-icon' src='./../img/gallinago_logo.png' alt='gallinago logo'></img>
                          </NavLink> */}
                          <Link className='navigation-menu-link' to='section-header' smooth={true}>
                              <img className='navigation-icon' src='./../img/gallinago_logo.svg' alt='gallinago logo'></img>
                          </Link>
                      </div>
                      <div className='navigation-right'>
                          <input id='navigation-toggle' className='navigation-toggle' type='checkbox' defultchecked='false'/>
                          <label className='hamburger nodeHover' htmlFor='navigation-toggle'>
                              <span className='middle-bar'></span>
                          </label>
                          <ul id='navigation-menu' className='navigation-menu'>
                              <li className='navigation-menu-item'><Link className='navigation-menu-link nodeHover' to='section-team' smooth={true}>About us</Link></li>
                              {/*<li className='navigation-menu-item'><Link className='navigation-menu-link nodeHover' to='section-clients' smooth={true}>Clients</Link></li>*/}
                              <li className='navigation-menu-item'><Link className='navigation-menu-link nodeHover' to='section-clients' smooth={true}>Portfolio</Link></li>
                              <li className='navigation-menu-item'><Link className='navigation-menu-link nodeHover' to='section-skills' offset={-100} smooth={true}>Skills</Link></li>
                              <li className='navigation-menu-item'><Link className='navigation-menu-link nodeHover' to='section-contact' smooth={true}>Contact</Link></li>
                          </ul>
                      </div>
                    </div>
                  </div>
                </nav>
                <div className='container-main'>
                    <div className='content'>
                        <Route exact path='/' component={Home} />
                    </div>
                </div>
            </BrowserRouter>
        )
    }
}

export default Main;

