import React, {Component} from 'react';

class Project extends Component {

    render() {
        return (
            <section className='section section-projects'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <h2 className='heading-secondary heading-secondary--dark'>Featured work</h2>
                  </div>
                  <div className='col-12 project-item project-item-1'>
                    <div className='img-container img-container-1'>
                        <img alt='' src='./../img/olimp_sport_nutrition.jpg'></img>
                    </div>
                    <div className='description-container'>
                      <h3 className='project-title'>Olimp sport nutrition</h3>
                      <p className='project-description'>Olimp Laboratories Sp. z o.o. is a Polish pharmaceutical company which was established in 1990. The company operates as part of a group of companies comprised of Nutrifarm 
  Sp. z o.o. and Olimp Laboratories GmbH in Germany.</p>
                      {/* <button className='btn gallinago-btn--secondary project-button'>View now</button> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-5 col-xl-4 project-item project-item-2'>
                    <div className='img-container img-container-2'>
                        <img alt='' src='./../img/olive_pl.jpg'></img>
                    </div>
                    <h3 className='project-title'>Olive.pl</h3>
                    <p className='project-description'>Olive.pl - lingerie and clothing eCommerce designed for women and men who value extraordinary design and good quality.</p>
                    {/* <button className='btn gallinago-btn--secondary project-button'>View now</button> */}
                  </div>
                  <div className='col-12 offset-md-1 col-md-6 offset-xl-3 col-xl-5 project-item project-item-3'>
                    <div className='img-container img-container-3'>
                      <img alt='' src='./../img/winkhaus.jpg'></img>
                    </div>
                    <h3 className='project-title'>sklep.winkhaus.pl</h3>
                    <p className='project-description'>Winkhaus Polska is a manufacturer and producer of window and door accessories. They export their goods to most of the companies of the Eastern Europe.</p>
                    <p className='project-description'>Polish store: <a href="https://sklep.winkhaus.pl/pl/" target="_blank" rel="noopener noreferrer">sklep.winkhaus.pl</a></p>
                    <p className='project-description'>Czech store: <a href="https://obchod.winkhaus.cz/cz/" target="_blank" rel="noopener noreferrer">obchod.winkhaus.cz</a></p>
                     {/*<button className='btn gallinago-btn--secondary project-button'>View now</button>*/}
                  </div>
                  <div className='col-12 col-md-5 col-xl-4 project-item project-item-4'>
                    <div className='img-container img-container-2'>
                        <img alt='' src='./../img/pauzza.png'></img>
                    </div>
                    <h3 className='project-title'>pauzza.pl</h3>
                    <p className='project-description'>The Pauzza is an online boutique that was created especially for women who love stylish clothes, available at your fingertips without leaving your home.</p>
                    {/* <button className='btn gallinago-btn--secondary project-button'>View now</button> */}
                  </div>
                  {/* <div className='col-12 project-item project-item-5'>
                    <div className='img-container img-container-1'>
                        <img alt='' src='./../img/otokom.png'></img>
                    </div>
                    <div className='description-container'>
                      <h3 className='project-title'>Otokom</h3>
                      <p className='project-description'>Olimp Laboratories Sp. z o.o. is a Polish pharmaceutical company which was established in 1990. The company operates as part of a group of companies comprised of Nutrifarm 
  Sp. z o.o. and Olimp Laboratories GmbH in Germany.</p>
                      <button className='btn gallinago-btn--secondary project-button'>View now</button>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
        )
    }
}

export default Project;

