import React, {Component} from 'react';
import { Link } from 'react-scroll'

function forceOpenElement() {
    window.setTimeout(() => {
        let buildProduct = document.querySelector('.contact-button');
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          buildProduct.dispatchEvent(evt);
    }, 1000)
};
class Header extends Component {
    render() {

        return (
            <header className='section section-header' id='section-header'>
                <div className='bg-video'>
                  <video className='bg-video__content' autoPlay muted loop>
                        <source src='./video/bg_video.mp4' type='video/mp4' />
                  </video> 
                </div>
                <div className='container'>
                    <h1 className='heading-primary'>Quality driven eCommerce &&nbsp;IT solutions</h1>
                    <p className='heading-primary--description'>We deliver customer focused services.</p>
                    <Link to='section-contact' smooth={true} className='btn gallinago-btn--primary' onClick={forceOpenElement}>Contact</Link>
                </div>
            </header>
        )
    }
}

export default Header;

