import React, {Component} from 'react';
import ClutchWidgetLoader from './ClutchWidgetLoader';

class Clutch extends Component {

    render() {
        return (
            <section className='section section-clutch'>
              <div className='container'>
                <div className='clutch-container'>
                    <img className='clutch-logo' alt='Clutch logo' src='./../img/clutch_logo.svg'></img>

                    <p className='clutch-desc'>Our Customers recommend us</p>
                  <div className='clutch-info'>
                  </div>
                    <ClutchWidgetLoader />
                  {/*<div className='clutch-stars'>*/}
                  {/*  <img className='clutch-star' alt='' src='./../img/clutch_star.svg'></img>*/}
                  {/*  <img className='clutch-star' alt='' src='./../img/clutch_star.svg'></img>*/}
                  {/*  <img className='clutch-star' alt='' src='./../img/clutch_star.svg'></img>*/}
                  {/*  <img className='clutch-star' alt='' src='./../img/clutch_star.svg'></img>*/}
                  {/*  <img className='clutch-star' alt='' src='./../img/clutch_star.svg'></img>*/}
                  {/*</div>*/}
                </div>
              </div>
            </section>
        )
    }
}

export default Clutch;

