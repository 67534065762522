import React, {Component} from 'react';

class SuccessMsg extends Component {
  render() {
      return (
        <div className="success-msg">
            <p>Thank you! Your message has been sent.</p>
        </div>
      )
  }
}

export default SuccessMsg;

