
document.addEventListener("DOMContentLoaded", function() {
  let menuItems = document.querySelectorAll('.navigation-menu-item');
  
  for (const menuItem of menuItems) {
      menuItem.addEventListener('click', function() {
          document.getElementById('navigation-toggle').checked = false;
          document.getElementById('section-menu').classList.remove('open')
      })
  }

  let navigationToggle = document.getElementById('navigation-toggle')

  navigationToggle.addEventListener('change', function(){

      if (document.getElementById('section-menu').classList.contains('open')) {
        window.setTimeout(function() {
          document.getElementById('section-menu').classList.remove('open')
        }, 200);
      } else {
        document.getElementById('section-menu').classList.add('open')
      }
  })

  window.addEventListener('scroll', function(e) {
    let scrollPosition = window.scrollY,
        navigation = document.getElementById('section-menu')
  
    if (scrollPosition > 1) {
      navigation.classList.add('sticky-navigation')
    } else {
      navigation.classList.remove('sticky-navigation')
    }
  });
})


