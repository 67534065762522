import React, {Component} from 'react';
// import Portfolio from './homepage-components/Portfolio';
import Header from './homepage-components/Header';
import Projects from './homepage-components/Projects';
import Clients from './homepage-components/Clients';
import Skills from './homepage-components/Skills';
import Clutch from './homepage-components/Clutch';
import Team from './homepage-components/Team';
import Contact from './homepage-components/Contact';
import Footer from './homepage-components/Footer';


class Home extends Component {
    render() {
        return (
            <div className='content-container'>
                <Header/>
                <Projects/>
                <Clutch/>
                <Clients/>
                <Skills/>
                {/*<Team/>*/}
                <Contact/>
                <Footer/>
            </div>
        )
    }
}

export default Home;