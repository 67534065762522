import React, { Component } from 'react';

class ClutchWidgetLoader extends Component {
    componentDidMount() {
        this.loadClutchWidget();
    }

    loadClutchWidget() {
        if (!window.clutchWidget) {
            const script = document.createElement('script');
            script.src = 'https://widget.clutch.co/static/js/widget.js';
            script.async = true;

            script.onload = () => this.initializeWidget();

            document.body.appendChild(script);
        } else {
            this.initializeWidget();
        }
    }

    initializeWidget() {
    }

    render() {
        return <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="14" data-height="50" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="1175724"></div>;
    }
}

export default ClutchWidgetLoader;
